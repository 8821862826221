<template>
	<base-layout>
		<h1 v-if="error" style="color: white">There was an error processing your request</h1>
		<h1 v-else style="color: white">Processing. Please wait.</h1>
	</base-layout>
</template>

<script>
import { tokenSignIn } from "@/services/firebase.js";

export default {
	data() {
		return {
			error: false,
		};
	},
	mounted() {
		const p = this.$route.query.p;

		if (typeof p === "undefined") {
			this.error = true;
		} else {
			try {
				tokenSignIn(p);
				this.$router.push("/signin/signup/details1");
			} catch (e) {
				console.log(e);
				this.error = true;
			}
		}
	},
};
</script>
